export default {
  debug: false,
  tableMetausers: `datagatheringapp_usersdata`,
  tableGroups: `datagatheringapp_groups`,
  tableLogs: `datagatheringapp_logs`,
  region: `us-east-1`,
  bucket: `datagatheringapp1a097af314bb4bffb92f960eaba5f9f11141-quality`,
  lambdaRegion: `ap-south-1`,
  lambdaARN: `arn:aws:lambda:ap-south-1:736883081464:function:lambda-webapp-criteria`,
  lambdaPathMale: `validated/Q-Brent-target/BNF_Brent_Hirosedsm_undefined__add4527851f61730bbc3cf99d0b687e93c5c6299a13eb79578ca894dc110a061/`,
  lambdaPathFemale: `validated/Q-Camille-target/BNF_Camille_Godfrey_undefined__ea24174f7b6c237dffda5cb485ca932dacc23a0aa8b37b246f2028cf8b639111/`,
  exceptionCorpus: [`default`,`dialogue`,`version`],
  blocksCorpus: [`dialogue`],
  durationMax: 30,
  durationMin: 0.2,
  abbreviationMapping: { etc: `etcetera` },
};
