import React,{ useContext,useEffect,useState } from 'react';
import { AppBar,Button,makeStyles,Toolbar,Modal } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoImg from '../logo.png';
import Context from '../Context';
import Settings from './Settings';
import { getModalStyle } from '../utils';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import sentry from '../sentry';


class UserReportedBug extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = `UserReportedBug`; // (2)
  }
}


const useStyles = makeStyles(theme => ({
  topbar: {
    background: `transparent!important`,
    boxShadow: `none`,
    [theme.breakpoints.down(`sm`)]: {
      paddingLeft: `60px`,
      marginRight: `60px`,
    },
  },
  title: { flexGrow: 1 },
  button: {
    color: `black`,
    minWidth: `10px`,
  },
  paper: {
    position: `absolute`,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid #000`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
  },
  leftPadded: { marginLeft: `1rem` },
  errorText: { color: `red` },
}));

export default function TopBar() {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const { user,signOut } = useContext(Context);
  const navigate = useNavigate();
  const [showLogout,setShowLogout] = useState(true);
  const [bugReporter,setBugReporter] = useState(false);
  const [bugText,setBugText] = useState(``);
  const [errorText,setErrorText] = useState(``);

  const submitReport = () => {
    if(bugText.length === 0) {
      setErrorText(`This is required`);
      return;
    }
    const report = new UserReportedBug(bugText);
    setBugReporter(false);
    setBugText(``);
    setErrorText(``);
    sentry(report);
  };

  useEffect(() => {
    // if this is inside iframe
    if (window.self !== window.top) 
      setShowLogout(false);
    
  });

  const handleBugDescriptionChange = evt => {
    setBugText(evt.target.value);
  };

  const closeBugReporter = () => {
    setBugReporter(false);
  };

  const showBugReporter = () => {
    setBugReporter(true);
  };

  const renderBugReporter = () => {
    return (
      <>
        <div style={modalStyle} className={classes.paper}>
          <h2>Bug Reporter</h2>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            multiline
            rows={2}
            value={bugText}
            onChange={handleBugDescriptionChange}
            maxRows={4}
            label="Please explain the issue you are facing" />

          <Typography component="span" className={classes.errorText}>
            {errorText} <br />
          </Typography>

          <Button onClick={submitReport} variant="outlined" title="Submit and Skip">
            Submit
          </Button>
          <Button className={`${classes.leftPadded}`} onClick={closeBugReporter} variant="outlined" >
            Cancel
          </Button>
         
        </div>
      </>
    );
  };

  const logout = e => {
    e.preventDefault();
    signOut(() => {
      navigate(`/`);
    });
  };
  return (
    <AppBar position="absolute" className={classes.topbar}>
      <Toolbar>
        <a title="Sanas" href="https://sanas.ai" target="_blank" rel="noreferrer"><img src={LogoImg} width="71" height="60" /></a>

        <div className={classes.title} />
        {user && (
          <>
            <Settings />
            {` `}
            {showLogout && (
              <>
                |

                <Button onClick={logout} title="Sign out" className={classes.button}>
                  <ExitToAppIcon />
                  {` `}
                </Button>
              </>
            )}
            |

            <Button onClick={showBugReporter} title="Report Bug" className={classes.button}>
              Report Bug
              {` `}
            </Button>
          </>
        )}
      </Toolbar>
      <Modal
        open={bugReporter}
        aria-labelledby="bug-reporter-modal-window"
        aria-describedby="bug-reporter-modal-window">
        {renderBugReporter()}
      </Modal>
    </AppBar>
  );
}
