import React,{ useContext,useEffect,useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  CircularProgress,
  Grid,makeStyles,
} from '@material-ui/core';
import { Link,useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Recorder from './Recorder';
import Authenticated from '../../_authenticated';
import MicSelector from '../../components/MicSelector';
import Context from '../../Context';
import { readMetauser } from '../../db';
import sentry from '../../sentry';
import Terms from './Terms';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(2,0,2),
    width: `100%`,
    textAlign: `center`,
  },
  trymic: {
    minHeight: `100px`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    width: `100%`,
  },
  trymiccont: {
    width: `100%`,
    minHeight: `100px`,
    marginBottom: `10px`,
    textAlign: `center`,
  },
  welcome: { textAlign: `center` },
  welcomebtn: {
    marginTop: `20px`,
    minWidth: `150px`,
  },
}));

export default function Index() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [goingPage,setGoingPage] = useState();
  const [meta,setMeta] = useState();
  const [loadingMeta,setLoadingMeta] = useState(true);
  const { group,loading,user } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!loading && user) 
      if (MediaRecorder.notSupported) 
        enqueueSnackbar(`Your browser may not support mic capturing, please try another browser`,{ variant: `error`,persist: true });
      else 
        readMetauser().then(u => {
          setMeta(u);
          setLoadingMeta(false);
        }).catch(err => {
          sentry(err);
        });
      
    
  },[loading,user,group]);

  const goPage = () => {
    setGoingPage(true);
    navigate(`/record`);
  };

  const welcomeBack = () => (
    <div className={classes.welcome}>
      <Typography variant="body1">
        Welcome back
        {` `}
        {meta.firstname}
        {` `}
        {meta.lastname}
        !
      </Typography>
      <Typography variant="body1">
        {` `}
        You already recorded
        {` `}
        {meta.totalRead}
        {` `}
        utterances.
      </Typography>
      <Typography variant="body1">
        Not you?
        {` `}
        <Link to="/metadata">Go back</Link>
        {` `}
        and use a different first or last name.
      </Typography>
    </div>
  );

  if (!meta) 
    return <CircularProgress />;
  

  return (
    <Authenticated>
      {meta.tos ? (
        <Container maxWidth="sm">

          <Box my={4}>

            <>
              <Typography variant="h4" component="h1" gutterBottom>
                Instructions
              </Typography>

              <Grid item>
                <ol>
                  <li>Read the prompt exactly how it is written on the screen.</li>
                  <li>Speak in a conversational manner, as you typically would talking with someone. </li>

                  <li>Re-record if you make any mistakes or mispronunciations. If there are any hesitations in your speed, please re-record as well.</li>
                  <li>Click the “Stop” button after reading the prompt, and then click “Next”.</li>
                  <li>We will evaluate your speech and you may need to re-record if it doesn’t meet the criteria.</li>
                  {group && group.countdown ? (
                    <li>
                      Please record more than
                      {` `}
                      {Math.floor(group.countdown / 60)}
                      {` `}
                      minutes
                    </li>
                  ) : null}
                </ol>

              </Grid>

              <div className={classes.trymic}>
                <MicSelector />
              </div>

              {loadingMeta ? (
                <CircularProgress />
              ) : ((meta && meta.totalRead > 1) ? welcomeBack() : null)}

              <Recorder goNext={goPage} goingPage={goingPage} />
            </>

          </Box>
        </Container>
      ) : (<Terms setMeta={setMeta} />) }

    </Authenticated>
  );
}
