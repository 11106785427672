/* eslint-disable no-restricted-syntax */
import { getUtterances } from './db';

const buildLocalDB = async(group,corpus,isTargetSpeaker,recordedUtts) => {
  const ids = [];
  const idPhraseMap = {};

  const pendingUttr = await Promise.all(corpus.split(`,`).map(c => getUtterances(group,c,isTargetSpeaker)));
  const flatUttr = pendingUttr.flatMap(x => x);

  for (const utt of flatUttr) {
    const key = `${utt.corpus}-${utt.id}`;

    if (recordedUtts.find(r => r.promptId === key)) 
      continue;
    

    ids.push(key);
    idPhraseMap[key] = utt.phrase;
  }
  return { ids,idPhraseMap };
};

const reader = async(group,recorderUtts,setToRecord) => {
  const { shuffle,corpus,targetSpeaker } = group;

  let lastId;
  let lastIndex = 0;

  const { ids,idPhraseMap } = await buildLocalDB(group,corpus,targetSpeaker,recorderUtts);

  if (setToRecord) 
    setToRecord(ids.length);
  
  const done = {};

  // Randomly choose one of IDs
  const seqChoice = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = lastIndex; i < ids.length; i++) {
      const nextObj = ids[i];
      if (!done[nextObj]) {
        done[nextObj] = true;
        lastIndex = i;
        return nextObj || ``;
      }
    }

    return ``;
  };
  const randomChoice = () => {
    const randId = Math.floor(Math.random() * (ids.length - 1));

    const dialogId = ids[randId];
    if (done[dialogId]) {
      lastIndex = randId;
      // Get next requential choice of randomly chosen one
      const seq = seqChoice();
      // if this is the end, try sequential from the beginning to avoid
      // saying it's finished with remaining recordings available
      if (!seq) {
        lastIndex = 0;
        return seqChoice();
      }

      return seq;
    }
    // Mark current recording as played / retrieved
    done[dialogId] = true;
    return dialogId;
  };

  return recordingsSafe => {
    let curId;
    // re-check if all success recordings (or in progress) doesn't repeat
    recordingsSafe.current.forEach(r => {
      if (r && r.sentence && r.sentence.id && r.status !== `error` && !done[r.sentence.id]) 
        done[r.sentence.id] = true;
      
    });
    if (shuffle) {
      curId = randomChoice();
      lastId = curId; // mark as false if all recordings are done
    } else {
      curId = seqChoice();
      lastId = ids[lastIndex + 1];
    }
    const curCorpus = curId && curId.split(`-`)[0];
    if (!curCorpus) 
      return { sentence: `No more utterances found` };
    
    const sentence = { phrase: idPhraseMap[curId],id: curId,corpus: curCorpus };
    return { sentence,lastId };
  };
};

export default reader;
