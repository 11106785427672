import React from 'react';
import { useSpeaker } from '../../hooks/useSpeaker';
import { useUser } from '../../hooks/useUser';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const RecordingInfo = ({ sentence }) => {
  const speaker = useSpeaker();
  const user = useUser();
  const ready = user && speaker && sentence?.id;
  return (
    <Container>
      {!ready && <CircularProgress/>}
      {ready && <>
        <Item>Prompt ID: <Bold>{sentence?.id}</Bold></Item>
        <Item>Group ID:<Bold>{user?.username}</Bold></Item>
        <Item>Speaker ID: <Bold>{speaker?.firstname} {speaker?.lastname}</Bold></Item>
      </>}
    </Container>
  );
};

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  padding:20px;
`;
const Item = styled.div`
`;
const Bold = styled.span`
  font-weight:bold;
`;
