import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: `https://8ca1c02c5d2b4b858fd414ba18ee25ab@o1129695.ingest.sentry.io/6173675`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend(event,hint) {
    const error = hint.originalException;
    if (
      error
      && error.message && (
        error.message.match(/Cannot read property 'userHash' of undefined/i)
        || error.message.match(/Request failed with status code 404/i)
        || error.message.match(/n is undefined/i)
        || error.message.match(/InvalidStateError/i)
        || error.message.match(/Network Error/i)
        || error.message.match(/Cannot read property 'byteLength' of undefined/i)
        || error.message.match(/Cannot record audio before microhphone is ready./i)
        || error.message.match(/Cannot stop audio before microphone is ready./i)
        || error.message.match(/Non-Error promise rejection captured with value: undefined/i)
        || error.message.match(/NotAllowedError: Permission denied/i)
        || error.message.match(/NotReadableError: Could not start audio source/i)
        || error.message.match(/AudioScheduledSourceNode/i)
        || error.message.match(/NotFoundError: Requested device not found/i)
        || error.message.match(/Signature expired/i)
        || error.message.match(/The user is not authenticated/i)
      )
    ) 
      return null;
    
    return event;
  },
});

console.log(`Sentry initialized`);

export default function sentry(err,infoOnly) {
  if (infoOnly) 
    Sentry.withScope(scope => {
      scope.setLevel(`info`);
      scope.setExtra(`info`,err);
      Sentry.captureMessage(infoOnly);
    });
  else {
    Sentry.captureException(err);
    console.error(err);
  }
}
