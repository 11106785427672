import React,{ useEffect } from 'react';
import {
  Avatar,
  Box,
  Chip,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  middleBox: {
    background: `#F9F8F8`,
    radius: `11px`,
    boxShadow: `0px 0px 4px 0px #00000040`,
    minHeight: `212px`,
    minWidth: `543px`,
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    [theme.breakpoints.down(`md`)]: { minWidth: `100%` },
  },
  label: {
    fontSize: `30px`,
    textTransform: `capitalize`,
  },
  labelDialogue: {
    fontSize: `20px`,
    textTransform: `capitalize`,
  },
  labelDialogueDiv: {},
  avatar: { fontSize: `1.4rem` },
  innerBox: {
    justifyContent: `center`,
    alignItems: `center`,
    display: `flex`,
    flexGrow: 1,
    width: `100%`,
  },

  middleText: {
    fontSize: `1.5625rem`,
    fontWeight: `400`,
    lineHeight: `40px`,
    letterSpacing: `0em`,
    padding: `20px`,
    textAlign: `left`,
    [theme.breakpoints.down(`sm`)]: {
      padding: `5px`,
      fontSize: `22px`,
      lineHeight: `35px`,
    },
  },
}));

const UtteranceBox = ({ sentence,status }) => {
  const classes = useStyles();

  const tagElement = (element,text) => {
    const label = element; // `(${id}) ${element}`;
    return (
      <div className={classes.labelDialogueDiv}>
        <Chip label={label} className={classes.labelDialogue} />
        <div>
          {` `}
          {text.replace(element,``)}
        </div>
      </div>
    );
  };

  const renderSentence = () => {
    if (!sentence) return null;
    const { phrase } = sentence;
    if (/AGENT:/gi.test(phrase)) 
      return tagElement(`AGENT:`,phrase);
    
    if (/CUSTOMER:/gi.test(phrase)) 
      return tagElement(`CUSTOMER:`,phrase);
    
    return phrase;
  };

  const renderEmotion = () => {
    if (!sentence || !sentence.emotion) return null;
    let emote;
    switch (sentence.emotion.toLowerCase()) {
      case `enthusiastic`:
        emote = `😄`;
        break;
      case `angry`:
        emote = `😡`;
        break;
      case `sad`:
        emote = `🙁`;
        break;
      case `worried`:
        emote = `😟`;
        break;
      case `lazy`:
        emote = `😴`;
        break;
      default:
        emote = `...`;
    }

    return (
      <Chip
        avatar={<Avatar className={classes.avatar}>{emote}</Avatar>}
        label={sentence.emotion}
        title="Read this sentence with this emotion. Listen to Target Audio for an example"
        color="primary"/>
    );
  };

  const [currentSentence,setCurrentSentence] = React.useState(renderSentence());

  useEffect(() => {
    setCurrentSentence(renderSentence());
  },[sentence]);

  return (
    <Box className={classes.middleBox}>
      <div className={classes.label}>
        {renderEmotion()}
      </div>
      <div className={classes.innerBox}>
        {/* <Typography className={classes.middleText} variant="h5" component="h1" align="center">
          {renderSentence()}
        </Typography> */}
        <TextField
          style={{ width: `90%` }}
          inputProps={{ style: { fontSize: `25px`,lineHeight: `35px` } }}
          size="medium"
          id="standard-multiline-flexible"
          value={currentSentence}
          onChange={e => setCurrentSentence(e.target.value)}
          multiline
          variant="standard"/>
      </div>

    </Box>
  );
};

export default UtteranceBox;
