/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */

import { useCallback,useEffect,useState } from 'react';
import sentry from './sentry';

const MD = () => navigator.mediaDevices;

export const useMicSelector = () => {
  const [deviceId,_setDeviceId] = useState();

  const setDeviceId = useCallback(dev => {
    localStorage.setItem(`inputdevice`,dev);
    _setDeviceId(dev);
  },[]);

  useEffect(() => {
    _setDeviceId(localStorage.getItem(`inputdevice`));
  },[]);

  return { deviceId,setDeviceId };
};

const getUserMedia = constraints => new Promise((resolve,deny) => {
  if (MD() && MD().getUserMedia) 
    MD().getUserMedia(constraints)
      .then(resolve,deny);
  if (navigator.getUserMedia) 
    navigator.getUserMedia(constraints,resolve,deny);
  else if (navigator.webkitGetUserMedia) 
    navigator.webkitGetUserMedia(constraints,resolve,deny);
  else if (navigator.mozGetUserMedia) 
    navigator.mozGetUserMedia(constraints,resolve,deny);
  else 
    deny(new Error(`not implemented`));
  
});

const findDevice = async(deviceId,idevices) => {
  let devices = idevices;
  // no MediaDevices so user cant select mic
  if (!MD()) return null;
  if (!devices) {
    const stream = await getUserMedia({ audio: true });
    // get devs list
    devices = await MD().enumerateDevices();
    const tracks = stream.getTracks();
    tracks.forEach(track => {
      track.stop();
    });
    try {
      stream.stop();
    } catch(err) {
      console.log(err);
    }
  }
  if (deviceId) 
    return devices.find(d => d.deviceId === deviceId) || devices[0];
  
  return devices[0];
};

export const getConstraints = async(deviceId,devices) => {
  const device = await findDevice(deviceId,devices);
  const audio = {
    audioGain: false,
    echoCancellation: false,
    noiseSuppression: false,
    channelCount: 1,
  };
  if (device) 
    // return found device (selected or first)
    return { audio: { ...audio,deviceId: { exact: device.deviceId } } };
  
  return { audio };
};

export const initMicrophone = async deviceId => {
  // Request audio access only

  // if (!MD()) {
  //   // No MediaDevices support, try to just return default audio
  //   const stream = await getUserMedia({ audio: true });
  //   return { stream, devices: [] };
  // }

  // get devs list
  try {
    const devices = await MD().enumerateDevices();
    const constraints = await getConstraints(deviceId,devices);
    const stream = await MD().getUserMedia(constraints);
    return { stream,devices };
  } catch (err) {
    console.error(err);
    const stream = await getUserMedia({ audio: true });
    return { stream,devices: [] };
  }
};

// console.log('-- All supported Audios : ', supportedAudios);

export class Recorder {
  constructor() {
    this.recorderListeners = {
      start: null,
      dataavailable: null,
      stop: null,
    };
    // this.analyze = this.analyze.bind(this);
  }

  /**
   * @param  {string[]} types           String array of types
   * @param  {string[]} codecs          String array of codecs
   *
   * @return {string[]}                 Array of accepted "mimetype;codec"
   */
  getSupportedMimeTypes() {
    const types = [`webm`,`ogg`,`mp3`,`x-matroska`];
    const codecs = [`vp9`,`vp9.0`,`vp8`,`vp8.0`,`avc1`,`av1`,`h265`,`h.265`,`h264`,`h.264`,`opus`,`pcm`,`aac`,`mpeg`,`mp4a`];

    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach(type => {
      const mimeType = `audio/${type}`;
      codecs.forEach(codec => [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs:${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
        `${mimeType};codecs:${codec.toUpperCase()}`,
      ].forEach(variation => {
        if (isSupported(variation)) supported.push(variation); 
      }));
      if (isSupported(mimeType)) supported.push(mimeType); 
    });
    return supported;
  }

  isReady() {
    return !!this.microphone;
  }

  async getMicrophone(deviceId) {
    const constraints = await getConstraints(deviceId);
    return getUserMedia(constraints);
  }

  // analyze() {
  //   this.analyzerNode.getByteFrequencyData(this.frequencyBins);
  //   if (this.volumeCallback) {
  //     this.volumeCallback(Math.max(...this.frequencyBins));
  //   }
  // }

  setVolumeCallback(cb) {
    this.volumeCallback = cb;
  }

  async init(deviceId) {
    if (this.isReady()) 
      return;
    
    const microphone = await this.getMicrophone(deviceId);
    this.microphone = microphone;

    const supportedAudios = this.getSupportedMimeTypes();
    const supportedWAV = supportedAudios.find(s => s.toLowerCase().indexOf(`wav`) > -1) || supportedAudios.find(s => s.toLowerCase().indexOf(`pcm`) > -1);

    if (!supportedWAV) {
      alert(`No WAVE/PCM codec supported for this browser, please try latest Chrome or Firefox`);
      throw Error(`NO WAV codec`);
    } else 
      console.log(`Codec found`,supportedWAV);
    
    this.recorder = new window.MediaRecorder(microphone,{ mimeType: supportedWAV });
    // this.recorder = new MediaRecorder(microphone, { mimeType: 'audio/wav' });
  }

  start() {
    if (!this.isReady()) {
      sentry(`Cannot record audio before microhphone is ready.`);
      return Promise.resolve();
    }
    return new Promise(res => {
      this.chunks = [];
      // Remove the old listeners.
      this.recorder.removeEventListener(`start`,this.recorderListeners.start);
      this.recorder.removeEventListener(`dataavailable`,this.recorderListeners.dataavailable);
      // Update the stored listeners.
      this.recorderListeners.start = e => res();
      this.recorderListeners.dataavailable = e => {
        this.chunks.push(e.data);
      };
      // Add the new listeners.
      this.recorder.addEventListener(`start`,this.recorderListeners.start);
      this.recorder.addEventListener(`dataavailable`,this.recorderListeners.dataavailable);
      // Finally, start it up.
      // We want to be able to record up to 60s of audio in a single blob.
      // Without this argument to start(), Chrome will call dataavailable
      // very frequently.
      // 250 was chosen to deal with a FF87.0 bug - see
      // https://github.com/webcompat/web-bugs/issues/67243#issuecomment-809777124
      // this.jsNode.onaudioprocess = this.analyze;
      this.recorder.start(250);
    });
  }

  stop() {
    if (!this.isReady()) {
      console.log(`Cannot stop audio before microphone is ready.`);
      return Promise.resolve();
    }
    return new Promise((res,rej) => {
      // this.jsNode.onaudioprocess = undefined;
      this.recorder.removeEventListener(`stop`,this.recorderListeners.stop);
      this.recorderListeners.stop = e => {
        const blob = new Blob(this.chunks,{ type: `audio/wav` });
        res({
          url: URL.createObjectURL(blob),
          blob,
        });
      };
      this.recorder.addEventListener(`stop`,this.recorderListeners.stop);
      try {
        this.recorder.stop();
      } catch(err) {
        console.log(err);
      }
    });
  }

  release() {
    if (this.microphone) 
      for (const track of this.microphone.getTracks()) 
        track.stop();
      
    
    this.microphone = null;
    // try {
    // this.nodes.forEach((node) => node.disconnect());
    // this.audioContext.close();
    // } catch (err) {
    // console.log(err);
    // }
  }
}
