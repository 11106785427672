/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React,{ useContext,useEffect,useState } from 'react';
import Context from './Context';

export default function Authenticated({ children }) {
  const [authorized,setAuthorized] = useState(false);
  const { user,loading,error } = useContext(Context);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loading) return; 
    if (!user) {
      navigate(`/`);
      setAuthorized(false);
    } else 
      setAuthorized(true);
    
  },[user,loading]);

  if (error) 
    enqueueSnackbar(error,{ variant: `error` });
  

  if (loading || !authorized) return <CircularProgress />;

  return children;
}
