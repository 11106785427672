import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: { main: `#78D0FC` },
    secondary: { main: `#ffffff` },
    error: { main: red.A400 },
    background: { default: `#fff` },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: `#fff`,
        borderRadius: `10px`,
      },
    },
  },
  typography: {
    fontFamily: [
      `Ubuntu`,
      `-apple-system`,
      `BlinkMacSystemFont`,
      `"Segoe UI"`,
      `Roboto`,
      `"Helvetica Neue"`,
      `Arial`,
      `sans-serif`,
      `"Apple Color Emoji"`,
      `"Segoe UI Emoji"`,
      `"Segoe UI Symbol"`,
    ].join(`,`),
    h4: { fontWeight: 700 },
  },
});

export default theme;
