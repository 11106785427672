import { Box,Button,Container,Typography } from '@material-ui/core';
import React,{ useState } from 'react';
import { acceptTermsOfService } from '../../db';

function Terms({ setMeta }) {
  const url = `https://sanas-terms-and-conditions.s3.amazonaws.com/Portal-Terms-of-Service.pdf`;
  const [loading,setLoading] = useState();

  const accept = () => {
    setLoading(true);
    acceptTermsOfService().then(resp => {
      setMeta(resp);
      setLoading(false);
    });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service
      </Typography>

      <Typography>
        You must accept the following terms of service:
      </Typography>

      <Box my={4}>
        <iframe title="Terms of Service" src={`${url}#toolbar=0&view=fitH&statusbar=0&navpanes=0`} type="application/pdf" width="100%" height="600px">
          {` `}
          <a href={url} target="_blank" rel="noreferrer">Open Terms of Service</a>
        </iframe>
      </Box>

      <Box my={4} style={{ textAlign: `right` }}>
        <Button variant="contained" color="primary" onClick={accept} disabled={loading}>I agree to the terms</Button>
      </Box>
    </Container>
  );
}

export default Terms;
