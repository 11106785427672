import axios from 'axios';
import { LambdaClient,InvokeCommand } from '@aws-sdk/client-lambda';
import Auth from '@aws-amplify/auth';
import config from './config';
import { log,getLocalMetadata } from './db';
import sentry from './sentry';
import { getSource } from './db';

export async function callLambdaFn(sentence,key,group) {
  const credentials = await Auth.currentCredentials();
  const { gender } = getLocalMetadata();

  const lambda = new LambdaClient({
    region: config.lambdaRegion,
    credentials,
  });

  const femalePath = group.lambdaPathFemale && group.lambdaPathFemale.length > 0 ? group.lambdaPathFemale : config.lambdaPathFemale;
  const malePath = group.lambdaPathMale && group.lambdaPathMale.length > 0 ? group.lambdaPathMale : config.lambdaPathMale;

  const target = `${gender === `Female` ? femalePath : malePath}${sentence.id}.npz`;
  const body = {
    source: key,
    target: target.replace(`PH__`,``).replace(`IN__`,``).replace(`PHTEST__`,``),
  };
  if (group.targetSpeaker == true)
    body.target = `indian_first_corpus/indfirst_01/bnfs/${sentence.id}.npz`;

  const command = new InvokeCommand(
    {
      FunctionName: config.lambdaARN,
      Payload: JSON.stringify(body),
    },
  );

  const response = await lambda.send(command);

  const asciiDecoder = new TextDecoder(`ascii`);
  const data = JSON.parse(asciiDecoder.decode(response.Payload));

  const success = data.statusCode === 202;
  return { success,message: data.body };
}
