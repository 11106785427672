import React,{ useContext } from 'react';
import {
  Badge,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Context from '../../../Context';
import CounterBar from './CounterBar';
import { getTime } from '../../../timer';

const useStyles = makeStyles(() => ({
  counter: { margin: `5px` },
  errors: {
    margin: `0 15px`,
    height: `5px`,
  },
}));

const Counter = ({ totalTime,totalTargetTime,totalUniqueErrors,countdown,successTotal,toRecord }) => {
  const classes = useStyles();
  const { group } = useContext(Context);
  // const [seconds, setSeconds] = useState(0);
  // useEffect(() => {
  //   // if (group && group.shuffle) {
  //   const timer = setInterval(() => {
  //     setSeconds(seconds + 1);
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // });

  const missing = countdown && totalTime < countdown;
  const showTime = missing ? countdown - totalTime : totalTime;
  const time = getTime(showTime);

  let label = `Recorded: ${time}`;
  if (countdown) 
    if (missing) 
      label = `Remaining: ${time}`;
    else 
      label = (<span style={{ fontWeight: 700,color: `green` }}>{`Completed! ${time}`}</span>);
  

  const errorLabel = <Badge badgeContent={totalUniqueErrors} className={classes.errors} title={`Total unique recordings failed: ${totalUniqueErrors}`} color="error" />;

  if (group && group.shuffle) 
    return (
      <>
        <Typography className={classes.counter}>
          {`Done ${successTotal}`}
          {errorLabel}
        </Typography>
        {toRecord && <Typography className={classes.counter}>
          {`Eligible recordings: ${toRecord}`}
        </Typography>}
        <Typography className={classes.counter}>
          {label}
        </Typography>
        {countdown ? <CounterBar value={Math.min(100,100 * (totalTime / countdown))} /> : null}
      </>
    );
  
  return (
    <>
      <Typography className={classes.counter}>
        {`Done ${successTotal}`}
        {` `}
        {errorLabel}

      </Typography>
      {toRecord && <Typography className={classes.counter}>
        {`Eligible recordings: ${toRecord}`}
      </Typography>}
      <Typography className={classes.counter}>
        Recorded:
        {` `}
        {group && group.targetSpeaker && `(${getTime(totalTargetTime)})`}
      </Typography>
    </>
  );
};

export default Counter;
