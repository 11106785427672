import levenshtein from 'js-levenshtein';
import config from './config';
import writtenNumber from './written-number';

export function deabbreviate(txt) {
  let ans = txt.toLowerCase();
  Object.keys(config.abbreviationMapping).forEach(key => {
    const r = new RegExp(`\\b${key}\\b`,`gi`);
    ans = ans.replace(r,config.abbreviationMapping[key]);
  });
  ans = ans.replace(/(\d+)/gi,(m,c) => writtenNumber(c));
  return ans;
}

export function lettersText(txt) {
  return deabbreviate(txt.toLowerCase()).replace(/[^a-z]/g,``);
}

// How much 2 sentences are difference, from 0 to 100 in percent style
export function diffTxt(expected,txt) {
  return Math.round((100 * levenshtein(expected,txt)) / expected.length);
}

if (typeof window !== `undefined`) {
  window.writtenNumber = writtenNumber;
  window.diffTxt = diffTxt;
}

export function guessDuration(sentence,targetDuration) {
  if (!(sentence && sentence.emotion) && targetDuration) 
    return targetDuration;
  
  const letters = lettersText(sentence.phrase);
  return (letters.length + 3.338) / 16.67;
}


export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
