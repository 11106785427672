import React,{ useContext,useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import Context from '../Context';

export default function Index() {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const groupId = window.localStorage.getItem(`groupId`);
    if (user && groupId) 
      navigate(`/metadata`);
    
  },[user]);

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <LoginForm />
      </Box>
    </Container>
  );
}
