import React,{ useCallback,useContext,useRef,useState } from 'react';
import { Button,makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import MicIcon from '@material-ui/icons/MicNone';
import WaveSurfer from '../../components/WaveSurfer';
import Context from '../../Context';
import { Recorder } from '../../audio';
import sentry from '../../sentry';
import { uploadFile } from '../../storage';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(1,0),
    width: `100%`,
    textAlign: `center`,
  },
  mic: {
    margin: `20px`,
    borderRadius: `50%`,
    width: `40px`,
    height: `40px`,
  },
  please: { fontWeight: `bolder` },
  timer: {
    display: `inline-flex`,
    width: `90px`,
  },
}));

export default withSnackbar(({ enqueueSnackbar,goNext,goingPage }) => {
  const classes = useStyles();
  const [rawAudio,setRawAudio] = useState();
  const [currentAudio,setCurrentAudio] = useState();
  const [recording,setRecording] = useState(false);
  const [player,setPlayer] = useState();
  const [playing,setPlaying] = useState(false);
  const { deviceId } = useContext(Context);
  const [timer,setTimer] = useState(0);
  const timercall = useRef();

  const recorder = useRef();

  const record = useCallback(() => {
    if (recording) {
      // Stop recording
      timercall.current && clearInterval(timercall.current);
      timercall.current = null;
      recorder.current.stop().then(({ blob }) => {
        setRecording(false);
        setRawAudio(blob);
        setCurrentAudio(URL.createObjectURL(blob));
        recorder.current.release();
        recorder.current = null;
      });
    } else {
      // Start recording
      timercall.current && clearInterval(timercall.current);
      setTimer(0);
      let t = 0;
      timercall.current = setInterval(() => {
        setTimer(++t);
      },1000);
      if (recorder.current) {
        recorder.current.stop();
        recorder.current.release();
      }
      recorder.current = new Recorder();
      recorder.current
        .init(deviceId)
        .then(() => {
          recorder.current.start().then(() => setRecording(true));
        })
        .catch(err => sentry(err));
    }
  },[timercall,timer,enqueueSnackbar,recorder,recording,deviceId]);

  const play = () => {
    if (playing) 
      player.pause();
    else 
      player.play();
    
  };

  const retry = () => {
    setRecording(false);
    setCurrentAudio(null);
  };

  const next = async() => {
    setRecording(false);
    const duration = player.getDuration();
    if (duration < Math.min(5)) {
      enqueueSnackbar(`Your recording duration is too short (${duration} seconds), please record again`,{ variant: `error` });
      setCurrentAudio(null);
    } else if (duration > 30) {
      enqueueSnackbar(`Your recording duration is too long (${duration} seconds), please record again`,{ variant: `error` });
      setCurrentAudio(null);
    } else {
      uploadFile(rawAudio)
        .then(c => goNext())
        .catch(err => {
          sentry(err);
          enqueueSnackbar(`Error submitting background sound, please try again`,{ variant: `error` });
        });
      setPlaying(false);
    }
  };

  return (
    <div className={classes.submit}>
      <div>
        <Typography variant="body1" className={classes.please}>
          Please record your background noise for 5 seconds if you didn&apos;t before.
        </Typography>
        <Typography variant="body1">
          <a href="#" disabled={goingPage} onClick={goNext}>
            {` `}
            Skip it
          </a>
          {` `}
          if you already recorded
        </Typography>
      </div>
      <Button variant="contained" onClick={retry} color="primary" disabled={!currentAudio}>
        Retry
      </Button>

      <Button
        variant="contained"
        onClick={play}
        color="secondary"
        className={classes.mic}
        style={{ display: `${currentAudio ? `inline-flex` : `none`}` }}>
        {playing ? <StopIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
      </Button>

      <Button
        variant="contained"
        onClick={record}
        color="secondary"
        className={classes.mic}
        style={{ display: `${!currentAudio ? `inline-flex` : `none`}` }}>
        {recording ? <StopIcon fontSize="large" /> : <MicIcon fontSize="large" />}
      </Button>

      {recording ? (
        <div className={classes.timer}>{`${timer} s`}</div>
      ) : (
        <Button variant="contained" onClick={next} color="primary" disabled={!currentAudio}>
          Let's Go
        </Button>
      )}

      <WaveSurfer
        audio={currentAudio}
        setPlayer={setPlayer}
        setPlaying={setPlaying}
        setRawAudio={setRawAudio}
        id="micAudioBg"
        micMode={recording}/>
    </div>
  );
});
