import React from 'react';
import sentry from '../../sentry';
import ClientWaveSurfer from './ClientWaveSurfer';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error,errorInfo) {
    console.log(`catch error`,error,errorInfo);
    sentry(error);
  }

  render() {
    if (this.state.hasError) 
      return null;
    

    return <ClientWaveSurfer {...this.props} />;
  }
}
