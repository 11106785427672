import en from './en.json';
import util from './util';

const languages = [`en`];
const i18n = { en };

const shortScale = [100];
for (var i = 1; i <= 16; i++) 
  shortScale.push(Math.pow(10,i * 3));


const longScale = [100,1000];
for (i = 1; i <= 15; i++) 
  longScale.push(Math.pow(10,i * 6));


/**
 * Converts numbers to their written form.
 *
 * @param {Number} n The number to convert
 * @param {Object} [options] An object representation of the options
 * @return {String} writtenN The written form of `n`
 */

function writtenNumber(n,options) {
  options = options || {};
  options = util.defaults(options,writtenNumber.defaults);

  if (n < 0) 
    return ``;
  

  n = Math.round(+n);

  let language = typeof options.lang === `string`
    ? i18n[options.lang]
    : options.lang;

  if (!language) {
    if (languages.indexOf(writtenNumber.defaults.lang) < 0) 
      writtenNumber.defaults.lang = `en`;
    

    language = i18n[writtenNumber.defaults.lang];
  }

  let scale = language.useLongScale ? longScale : shortScale;
  let { units } = language;
  let unit;

  if (!(units instanceof Array)) {
    const rawUnits = units;

    units = [];
    scale = Object.keys(rawUnits);

    //eslint-disable-next-line
    for (var i in scale) {
      units.push(rawUnits[scale[i]]);
      scale[i] = Math.pow(10,parseInt(scale[i]));
    }
  }

  const baseCardinals = language.base;
  const alternativeBaseCardinals = options.alternativeBase
    ? language.alternativeBase[options.alternativeBase]
    : {};

  if (language.unitExceptions[n]) return language.unitExceptions[n];
  if (alternativeBaseCardinals[n]) return alternativeBaseCardinals[n];
  if (baseCardinals[n]) return baseCardinals[n];
  if (n < 100) return handleSmallerThan100(n,language,unit,baseCardinals,alternativeBaseCardinals,options); 

  const m = n % 100;
  let ret = [];

  if (m) 
    if (
      options.noAnd
      && !(language.andException && language.andException[10])
    ) 
      ret.push(writtenNumber(m,options));
    else 
      ret.push(language.unitSeparator + writtenNumber(m,options));
  

  let firstSignificant;

  for (let i = 0,len = units.length; i < len; i++) {
    let r = Math.floor(n / scale[i]);
    var divideBy;

    if (i === len - 1) divideBy = 1000000;
    else divideBy = scale[i + 1] / scale[i];

    r %= divideBy;

    unit = units[i];

    if (!r) continue;
    firstSignificant = scale[i];

    if (unit.useBaseInstead) {
      const shouldUseBaseException = unit.useBaseException.indexOf(r) > -1
        && (unit.useBaseExceptionWhenNoTrailingNumbers
          ? i === 0 && ret.length
          : true);
      if (!shouldUseBaseException) 
        ret.push(alternativeBaseCardinals[r * scale[i]] || baseCardinals[r * scale[i]]);
      else 
        ret.push(r > 1 && unit.plural ? unit.plural : unit.singular);
      
      continue;
    }

    var str;
    if (typeof unit === `string`) 
      str = unit;
    else if (r === 1 || unit.useSingularEnding && r % 10 === 1
      && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0)) 
      str = unit.singular;
    else if (unit.few && (r > 1 && r < 5 || unit.useFewEnding && r % 10 > 1 && r % 10 < 5
      && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0))) 
      str = unit.few;
    else {
      str = unit.plural && (!unit.avoidInNumberPlural || !m)
        ? unit.plural
        : unit.singular;

      // Languages with dual
      str = (r === 2 && unit.dual) ? unit.dual : str;

      // "restrictedPlural" : use plural only for 3 to 10
      str = (r > 10 && unit.restrictedPlural) ? unit.singular : str;
    }

    if (
      unit.avoidPrefixException
      && unit.avoidPrefixException.indexOf(r) > -1
    ) {
      ret.push(str);
      continue;
    }

    const exception = language.unitExceptions[r];
    const number = exception
      || writtenNumber(
        r,
        util.defaults(
          {
            // Languages with and exceptions need to set `noAnd` to false
            noAnd: !((language.andException && language.andException[r])
              || unit.andException) && true,
            alternativeBase: unit.useAlternativeBase,
          },
          options,
        ),
      );
    n -= r * scale[i];
    ret.push(`${number} ${str}`);
  }

  const firstSignificantN = firstSignificant * Math.floor(n / firstSignificant);
  const rest = n - firstSignificantN;

  if (
    language.andWhenTrailing
    && firstSignificant
    && rest > 0
    && ret[0].indexOf(language.unitSeparator) !== 0
  ) 
    ret = [ret[0],language.unitSeparator.replace(/\s+$/,``)].concat(
      ret.slice(1),
    );
  

  // Languages that have separators for all cardinals.
  if (language.allSeparator) 
    for (let j = 0; j < ret.length - 1; j++) 
      ret[j] = language.allSeparator + ret[j];
    
  
  const result = ret.reverse().join(` `);
  return result;
}

writtenNumber.defaults = {
  noAnd: false,
  alternativeBase: null,
  lang: `en`,
};

function handleSmallerThan100(n,language,unit,baseCardinals,alternativeBaseCardinals,options) {
  const dec = Math.floor(n / 10) * 10;
  unit = n - dec;
  if (unit) 
    return (
      alternativeBaseCardinals[dec] || baseCardinals[dec] + language.baseSeparator + writtenNumber(unit,options)
    );
  
  return alternativeBaseCardinals[dec] || baseCardinals[dec];
}

export default writtenNumber;
