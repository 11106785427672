import React from 'react';
import { green,red } from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function StatusIcon({ status,title }) {
  let icon;
  switch (status) {
    case `success`:
      icon = (<CheckIcon style={{ color: green[500] }} />);
      break;
    case `uploading`:
      icon = (<CircularProgress />);
      break;
    case `error`:
      icon = (<ErrorOutlineIcon style={{ color: red[500] }} />);
      break;
    default:
      return null;
  }
  return <span title={title}>{icon}</span>;
}
