import { Collapse,IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import React,{ useEffect,useState } from 'react';
import { getTime } from '../../timer';

function CountdownAlert({ group,meta }) {
  const [open,setOpen] = useState(true);

  useEffect(() => {
    if (meta && group && meta.totalTime > group.countdown) 
      setOpen(true);
    
  },[meta && meta.totalTime]);

  if (!group || !meta || !group.countdown) return null;

  let severity = `warning`;
  let label = `You must record ${getTime(group.countdown)} in order to be eligible for payment`;

  if (meta.totalTime > group.countdown) {
    severity = `success`;
    label = `You fully completed the required time for recording.`;
  }
  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}>
        {label}

      </Alert>

    </Collapse>
  );
}

export default CountdownAlert;
