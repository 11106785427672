import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MetadataForm from '../components/MetadataForm';
import Authenticated from '../_authenticated';

export default function Index() {
  return (
    <Authenticated>
      <Container maxWidth="md">
        <Box my={4}>
          <MetadataForm />
        </Box>
      </Container>
    </Authenticated>
  );
}
