/* eslint-disable react/jsx-props-no-spreading */
import React,{ useContext,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller,useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getAuth,useAuth } from '../auth';
import Context from '../Context';

const useStyles = makeStyles(theme => ({
  form: {
    width: `100%`, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3,0,2),
    width: `100%`,
    textAlign: `center`,
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user,signIn,signing,loading,error } = useContext(Context);
  const navigate = useNavigate();
  const { register,handleSubmit,reset,watch,control,formState: { errors } } = useForm();
  const onSubmit = async data => {
    // set own password based in username
    const u = await signIn(data.groupId);
    if (u) 
      navigate(`/metadata`);
    
    // console.log(data);
  };

  useEffect(() => {
    const auth = getAuth();
    reset(auth);
  },[]);

  useEffect(() => {
    console.log(`user changed`,user);
  },[user]);

  useEffect(() => {
    if (error) enqueueSnackbar(error,{ variant: `error` }); 
  },[error]);

  if (loading) return null;

  return (
    <form data-testid="login-form" id="login-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="groupId"
        control={control}
        render={({ field }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Group ID"
            autoComplete="groupId"
            autoFocus
            {...field}/>
        )}/>

      <div className={classes.submit}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={signing}>
          Sign In Now
        </Button>
      </div>
    </form>
  );
}
