export const getTime = seconds => {
  const time = [];

  const hours = Math.floor(seconds / (60 * 60));
  if (hours) time.push(`${hours}h`);
  const mins = Math.floor((seconds / 60) % 60);
  if (mins) time.push(`${mins}min`);
  // if (mins < 10) mins = `0${mins}`;
  const secs = Math.floor(seconds % 60);
  if (secs) time.push(`${secs}sec`);
  // if (secs < 10) secs = `0${secs}`;

  return time.join(` `);
};
