/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7d4de353-4ec2-4a69-aff9-d0537aa3621e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nJzQdye6u",
    "aws_user_pools_web_client_id": "2dgd344ukeeorrn16qmm9pvufi",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "datagatheringapp1a097af314bb4bffb92f960eaba5f9f11141-quality",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
