import React from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import TopBar from './components/TopBar';
import Context from './Context';
import { useAuth } from './auth';
import Grid from './pages/_grid';
import './sentry';
import { useMicSelector } from './audio';
import Metadata from './pages/Metadata';
import Login from './pages/Login';
import Record from './pages/record/Record';
import Instructions from './pages/instructions';

export default function MyApp() {
  const {
    user,signOut,signIn,signing,loading,error,group, 
  } = useAuth();

  const { deviceId,setDeviceId } = useMicSelector();
  const history = createMemoryHistory();
  return (
    <>
      <Router location={history.location} navigator={history} >
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Context.Provider
              value={{
                signIn,
                user,
                signOut,
                signing,
                loading,
                error,
                group,
                deviceId,
                setDeviceId,
              }}>
              <CssBaseline />
              <TopBar />
              <Grid>
                <Routes>
                  <Route path="/instructions" element={<Instructions />} />
                  <Route path="/record" element={<Record />} />
                  <Route path="/metadata" element={<Metadata />} />
                  <Route path="/" element={<Login />} />
                </Routes>
              </Grid>
            </Context.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </>
  );
}
