import React,{ useState } from 'react';
import { Button,makeStyles,Modal } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import MicSelector from './MicSelector';
import WaveSurfer from './WaveSurfer';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  settings: {
    color: `black`,
    minWidth: `10px`,
  },

  paper: {
    position: `absolute`,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid #000`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
  },
}));

export default function Settings() {
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const [open,setOpen] = useState(false);
  const [micUpdate,setMicUpdate] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearCache = () => {
    window.localStorage.removeItem(`inputdevice`);

    Object.keys(window.localStorage).filter(key => {
      if (key.indexOf(`-lastdone`) > -1) return key;
      if (key.indexOf(`-done`) > -1) return key;
    }).forEach(key => {
      window.localStorage.removeItem(key);
    });

    window.location.reload();
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Settings</h2>
      <MicSelector onChange={() => setMicUpdate(Date.now())} />
      <WaveSurfer micMode forceReload={micUpdate} id="micsettings" />
      <hr />
      <h4>
        <a href="#" onClick={clearCache}>Clear Cache</a>
      </h4>

      <hr />

      <p>Shortcuts:</p>
      <ul>
        <li>Spacebar to start/stop recording or playing </li>
        <li>"R" to retry / clear current recording </li>
        <li>"N" to submit / go next </li>

      </ul>
    </div>
  );

  return (
    <div>
      <Button onClick={handleOpen} title="Open Settings" className={classes.settings}>
        <SettingsIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  );
}
